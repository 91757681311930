import ContainerCustom from "../components/ContainerCustom";
import Header from "../components/Header";

const Home = () => {
  return (
    <ContainerCustom bg="home">
      <Header />
    </ContainerCustom>
  );
};

export default Home;
